<template>
  <div v-if="!isLoading">
    <div class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">Customers Map</h3>
          <button class="btn btn-primary btn-flat btn-sm" @click="initMap()">Load Map</button>
        </div>
      </div>
      <div class="box-body no-padding" v-if="!collapsed">
        <!-- Google Maps -->
        <div id="maps" ref="maps"></div>
      </div>
    </div>
    <div class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Recent Active Merchants</h3>
          <div class="flex align-items-center gap-8">
            <label class="mb-0">
              <input v-model="verified" name="buy" type="radio"
                     value="1" @change="getAnalyticsData()"/> Verified</label>
            <label class="mb-0">
              <input v-model="verified" name="buy" type="radio"
                     value="0" @change="getAnalyticsData('buy')"/> Non Verified</label>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
            <tr>
              <th>ID</th>
              <th>Shop Title</th>
              <th>Owner Nick Name</th>
              <th>Status</th>
              <th>Last Login On</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, index) in data.recentActiveUsers" :key="index">
              <td>
                <router-link :to="{ name: 'user-detail', params: { id: user.ID } }" tag="a">
                  {{ user.ID }}
                </router-link>
              </td>
              <td>{{ user.ShopTitle }}</td>
              <td>{{ user.OwnerNickName }}</td>
              <td>
                <span v-html="formatStatus(user.FinalVerification)"></span>
              </td>
              <td>{{ user.LastLoginOn }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="box box-default">
      <div class="box-header">
        <h3 class="box-title">Input Currencies Usage Percentage</h3>
      </div>
      <div class="box-body no-padding">
        <div class="table-responsive">
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th>Input Currency</th>
              <th>Usage Percentage</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(currency, index) in currenciesUsage" :key="index">
              <td>{{ currency.InputCurrency }}</td>
              <td>{{ currency.Percentage }}%</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="box box-default" v-if="data.mostEnabledCurrency">
      <div class="box-header">
        <h3 class="box-title">Most Enabled Coin</h3>
      </div>
      <div class="box-body">
        <p>{{ data.mostEnabledCurrency.Name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "../store/keys";
import gmapsInit from "../lib/gmaps";

export default {
  name: "Analytics",
  data() {
    return {
      data: {
        recentActiveUsers: [],
        currenciesUsage: [],
        kycCyclic: [],
        mostEnabledCurrency: null,
      },
      verified: 1,
      collapsed: true,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "countries",
    ]),
    currenciesUsage() {
      const currencies = [];
      const total = this._.sumBy(this.data.currenciesUsage, function (c) {
        return c.Total;
      });
      this.data.currenciesUsage.forEach(c => {
        if (c.Total > 0) {
          currencies.push({
            Percentage: (c.Total / total * 100).toFixed(2),
            InputCurrency: c.InputCurrency,
          });
        }
      });
      return currencies;
    },
  },
  methods: {
    formatStatus(status) {
      const formattedStatus = status ? "Verified" : "Unverified";
      const lblClass = status ? "label-success" : "label-danger";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    getAnalyticsData() {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=Analytics`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
      }).then(response => {
        if (response.status === 200) {
          this.$store.commit(STOP_LOADING);
          this.data = response.data.data;
          if (!this.collapsed) {
            this.initMap();
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        console.log(error);
      });
    },
    async initMap() {
      try {
        this.collapsed = false;
        const google = await gmapsInit();
        const map = new google.maps.Map(document.getElementById("maps"));
        const bounds = new google.maps.LatLngBounds();
        const locations = [];
        for (let i = 0; i < this.data.kycCyclic.length; i++) {
          // eslint-disable-next-line promise/param-names
          const record = this.data.kycCyclic[i];
          let address = "";
          if (record.PayoutAddress && record.PayoutAddress !== "null") {
            address = record.PayoutAddress + " ";
          }
          if (record.PayoutCity && record.PayoutCity !== "null") {
            address += record.PayoutCity + " ";
          }
          if (record.PayoutState && record.PayoutState !== "null") {
            address += record.PayoutState + " ";
          }
          if (record.PayoutCountry) {
            address += this.countries[record.PayoutCountry];
          }
          address = address.trim();
          if (address) {
            locations.push({
              position: { lat: Number(record.Latitude), lng: Number(record.Longitude) },
              info: `<div>Shop Title: ${record.ShopTitle}</div><div>Owner Nick Name: ${record.OwnerNickName}</div><div>${address}</div>`,
              // viewport: results[0].geometry.viewport
            });
          }
        }
        const infoWindow = new google.maps.InfoWindow();

        let marker, i;

        for (i = 0; i < locations.length; i++) {
          marker = new google.maps.Marker({
            position: locations[i].position,
            map: map,
          });
          bounds.extend(marker.position);
          google.maps.event.addListener(marker, "click", (function (marker, i) {
            return function () {
              infoWindow.setContent(locations[i].info);
              infoWindow.open(map, marker);
            };
          })(marker, i));
        }
        map.setZoom(8);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getAnalyticsData();
  },
};
</script>

<style lang="scss" scoped>
#maps {
  width: 100%;
  height: 500px;
}
</style>
